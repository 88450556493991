<template>
  <div class="filters-menu-mobile">
    <PostalCodeInputDialog />

    <FiltersDialog />

    <SortDialog />

    <q-btn-group
      spread
      class="q-mt-none q-pa-none no-border-radius full-width no-shadow"
    >
      <q-btn
        unelevated
        :ripple="false"
        class="filters-menu-mobile__button --border-right"
        no-caps
        @click="promptFiltersDialog()"
      >
        <q-icon name="sym_r_tune" class="q-mr-sm" style="font-size: 1.5rem" />
        Filters
      </q-btn>
      <q-btn
        unelevated
        :ripple="false"
        class="filters-menu-mobile__button --border-right"
        no-caps
        @click="promptSortDialog()"
      >
        <q-icon name="sym_r_sort" class="q-mr-sm" style="font-size: 1.5rem" />
        Sort
      </q-btn>
      <q-btn
        unelevated
        :ripple="false"
        class="filters-menu-mobile__button"
        no-caps
        @click="promptPostalCodeInputDialog()"
      >
        <q-icon name="sym_r_place" class="q-mr-sm" style="font-size: 1.5rem" />
        {{ $store.state.search.location.postalCode }}
      </q-btn>
    </q-btn-group>
  </div>
</template>

<script>
import PostalCodeInputDialog from "@/components/PostalCodeInputDialog";
import { Dialog } from "quasar";
import FiltersDialog from "@/components/FiltersDialog.vue";
import SortDialog from "@/components/SortDialog.vue";

export default {
  name: "FiltersMobileMenubar",
  components: { SortDialog, FiltersDialog, PostalCodeInputDialog },
  methods: {
    promptPostalCodeInputDialog() {
      Dialog.create({
        component: PostalCodeInputDialog
      });
    },
    promptFiltersDialog() {
      Dialog.create({
        component: FiltersDialog
      });
    },
    promptSortDialog() {
      Dialog.create({
        component: SortDialog
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.filters-menu-mobile {
  width: 100%;
  position: sticky;
  top: $titlebar-height;
  z-index: 100;
  background: color(background, light);

  .filters-menu-mobile__button {
    padding: custom-space(3) $space-sm;
  }
}
</style>
