<template>
  <q-item>
    <div class="full-width">
      <div class="row q-col-gutter-md">
        <q-select
          filled
          dense
          :model-value="internalModelValue.min"
          @update:model-value="
            val => {
              handleModelValueUpdate({
                min: val,
                max: internalModelValue.max
              });
              $emit('change');
            }
          "
          :label="'Min ' + inputLabel"
          :options="minOptions"
          map-options
          emit-value
          hide-dropdown-icon
          class="col-6"
          :disable="disabled"
        />
        <q-select
          filled
          dense
          :model-value="internalModelValue.max"
          @update:model-value="
            val => {
              handleModelValueUpdate({
                min: internalModelValue.min,
                max: val
              });
              $emit('change');
            }
          "
          :label="'Max ' + inputLabel"
          :options="maxOptions"
          map-options
          emit-value
          hide-dropdown-icon
          class="col-6"
          :disable="disabled"
        />
      </div>
    </div>
  </q-item>
  <q-item>
    <q-range
      :model-value="internalModelValue"
      @update:model-value="val => handleModelValueUpdate(val)"
      @change="$emit('change')"
      :min="rangeMin"
      :max="rangeMax"
      :step="stepSize"
      color="secondary"
      style="width:94%;margin:0 auto"
      :disable="disabled"
      :reverse="false"
      :readonly="rangeMax - rangeMin == stepSize"
    />
  </q-item>
</template>

<script>
export default {
  name: "RangeWithInputs",
  props: {
    modelValue: {
      required: true
    },
    inputLabel: {
      type: String,
      default: ""
    },
    min: {
      default: null
    },
    max: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    stepSize: {
      type: Number,
      default: 1
    },
    inputPrefix: {
      type: String,
      default: ""
    },
    numberFormattedInput: {
      type: Boolean,
      default: false
    },
    strictRange: {
      type: Boolean,
      default: false
    },
    preventMinMaxOverlap: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue", "change"],
  computed: {
    rangeMin() {
      return (
        (this.modelValue.min === null
          ? this.min
          : this.modelValue.min < this.min
          ? this.modelValue.min
          : this.min) ?? 0
      );
    },
    rangeMax() {
      return (
        (this.modelValue.max === null
          ? this.max
          : this.modelValue.max > this.max
          ? this.modelValue.max
          : this.max) ?? 0
      );
    },
    internalModelValue() {
      return {
        min: parseInt(
          (this.modelValue.min === null
            ? this.rangeMin
            : this.modelValue.min
          ).toString()
        ),
        max: parseInt(
          (this.modelValue.max === null
            ? this.rangeMax
            : this.modelValue.max
          ).toString()
        )
      };
    },
    minOptions() {
      let options = [];
      let startingOption = this.rangeMin;
      if (startingOption === null) return options;

      while (startingOption < this.internalModelValue.max) {
        let optionValue = startingOption;
        options.push({
          value: optionValue,
          label:
            (this.inputPrefix.length > 0 ? this.inputPrefix : "") +
            (this.numberFormattedInput
              ? this.formatNumber(optionValue)
              : optionValue)
        });
        startingOption = startingOption + this.stepSize;
      }
      return options;
    },
    maxOptions() {
      let options = [];
      let startingOption = this.internalModelValue.min + this.stepSize;
      if (startingOption === null) return options;

      while (startingOption < this.rangeMax + this.stepSize) {
        let optionValue = startingOption;
        options.push({
          value: optionValue,
          label:
            (this.inputPrefix.length > 0 ? this.inputPrefix : "") +
            (this.numberFormattedInput
              ? this.formatNumber(optionValue)
              : optionValue) +
            (optionValue == this.rangeMax ? "+" : "")
        });
        startingOption = startingOption + this.stepSize;
      }
      options.sort((a, b) => b.value - a.value);

      return options;
    }
  },
  methods: {
    handleModelValueUpdate(newVal) {
      let emitVal = newVal;

      // min/max value validation (null out min/max if they are currently at the default min/mix range value, etc)
      if (emitVal.min !== null) {
        emitVal.min = parseInt(emitVal.min.toString().replace(/\D/g, ""));
        emitVal.min = isNaN(emitVal.min) ? -1 : emitVal.min;
        if (
          emitVal.min < 0 ||
          emitVal.min <= this.min ||
          emitVal.min > (emitVal.max ?? this.rangeMax) ||
          (emitVal.min == this.rangeMin && this.rangeMin == this.min)
        ) {
          emitVal.min = null;
        }
      }
      if (emitVal.max !== null) {
        emitVal.max = parseInt(emitVal.max.toString().replace(/\D/g, ""));
        emitVal.max = isNaN(emitVal.max) ? -1 : emitVal.max;
        if (
          emitVal.max < 0 ||
          emitVal.max >= this.max ||
          emitVal.max < (emitVal.min ?? this.rangeMin) ||
          (emitVal.max == this.rangeMax && this.rangeMax == this.max)
        ) {
          emitVal.max = null;
        }
      }

      // if needed, validate min/max are not same value
      if (this.preventMinMaxOverlap) {
        if (
          emitVal.min == emitVal.max &&
          emitVal.min !== null &&
          emitVal.max !== null
        ) {
          if (emitVal.min == this.modelValue.min) emitVal.max += this.stepSize;
          else if (emitVal.max == this.modelValue.max)
            emitVal.min -= this.stepSize;
        } else if (emitVal.min == null || emitVal.max == null) {
          if (emitVal.max == this.rangeMin)
            emitVal.max = this.rangeMin + this.stepSize;
          else if (emitVal.min == this.rangeMax)
            emitVal.min = this.rangeMax - this.stepSize;
        }
      }

      this.$emit("update:modelValue", emitVal);
    }
  }
};
</script>

<style scoped lang="scss">
.q-field_prefix {
  padding-right: 0px !important;
}
</style>
