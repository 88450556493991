<template>
  <div class="action-dialog-header">
    <h5 class="flex items-center q-ma-none ellipsis">
      <q-icon
        v-if="headerIcon.length > 0"
        :name="headerIcon"
        class="q-mr-sm"
        style="font-size: 1.4rem"
      />
      {{ header }}
    </h5>

    <q-btn
      flat
      round
      icon="sym_r_close"
      :ripple="false"
      v-close-popup
      class="--icon-button"
      color="gray"
    />
  </div>
</template>

<script>
export default {
  name: "DialogHeader",
  props: {
    headerIcon: {
      type: String,
      default: ""
    },
    header: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.action-dialog-header {
  background: color(background, light);
  @include display-flex(row, space-between, center);
  padding: $space-sm $space-md;
  box-shadow: $shadow-bottom;
  flex-wrap: nowrap;
  z-index: 1;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
</style>
