<template>
  <div class="sort-dialog-container">
    <q-dialog :ref="modalRef" v-cloak full-width position="bottom">
      <DialogHeader header="Sort" header-icon="sym_r_sort" />

      <q-card style="width: 100%">
        <q-card-section class="q-mt-sm">
          <q-tabs
            align="left"
            narrow-indicator
            indicator-color="secondary"
            dense
            class="full-width q-pb-none q-mb-md --tab-pills"
            v-model="searchMode"
          >
            <q-tab
              name="classic"
              label="Classic"
              :ripple="false"
              style="width: 50%"
            />
            <q-tab
              name="advanced"
              label="Advanced"
              :ripple="false"
              style="width: 50%"
            />
          </q-tabs>
        </q-card-section>

        <q-tab-panels v-model="searchMode" animated class="bg-transparent">
          <q-tab-panel name="classic" class="q-pa-none">
            <q-form @submit="submitSortForm()">
              <q-list class="q-pb-md">
                <q-item
                  v-for="(option, i) in $store.state.search.sort.options
                    .classic"
                  :key="i + '_sortDialogFilterOption'"
                  :inset-level="0"
                  v-ripple
                  dense
                  :disable="disableSubmit"
                  tag="label"
                >
                  <q-item-section side>
                    <q-radio
                      v-model="sortValue"
                      :val="option.value"
                      color="secondary"
                      :disable="disableSubmit"
                      @click="submitSortForm()"
                    />
                  </q-item-section>
                  <q-item-section>
                    {{ option.label }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-form>
          </q-tab-panel>

          <q-tab-panel name="advanced" class="q-pa-none">
            <q-form @submit="submitSortForm()">
              <q-list class="q-pb-md">
                <q-item
                  v-for="(option, i) in $store.state.search.sort.options
                    .advanced"
                  :key="i + '_sortDialogFilterOption'"
                  :inset-level="0"
                  v-ripple
                  dense
                  :disable="disableSubmit"
                  tag="label"
                >
                  <q-item-section side>
                    <q-radio
                      v-model="sortValue"
                      :val="option.value"
                      color="secondary"
                      :disable="disableSubmit"
                      @click="submitSortForm()"
                    />
                  </q-item-section>
                  <q-item-section>
                    {{ option.label }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-form>
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DialogHeader from "@/components/UI/DialogHeader.vue";

export default {
  name: "SortDialog",
  components: { DialogHeader },
  data() {
    return {
      modalRef: "sortDialog",
      sortValue: this.$store.state.search.sort.model,
      disableSubmit: false,
      searchMode: this.$store.state.search.sort.model.includes("classic")
        ? "classic"
        : "advanced"
    };
  },
  computed: {},
  mounted() {},
  methods: {
    show() {
      this.$refs[this.modalRef].show();
    },
    hide() {
      this.$refs[this.modalRef].hide();
    },
    submitSortForm() {
      this.$store.commit("search/SET_SORT", this.sortValue);
      this.$store.commit("search/RESET_PAGINATION");
      this.$store.commit("search/SET_LOADING");
      this.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.sort-dialog-container {
  background: color(background);
}
</style>
